<template>
    <section class="border br-t-12 custom-scroll d-middle-center overflow-auto mx-3 mt-2" style="height:calc(100vh - 60px);">
        <div>
            <div class="row mx-0 justify-center">
                <div class="col-auto text-center">
                    <img :src="url_image" style="max-width:1000%;height:345px;" />
                </div>
            </div>
            <div class="row mx-0 justify-center">
                <div class="col-7 text-center">
                    <p class="f-15 text-general my-3">
                        El pedido ID - {{ datos.id }} para <b>{{ datos.nombre_cliente }}</b> ha sido creado
                    </p>
                    <p class="f-15 text-general my-3">
                        Llegará el {{ datos.fecha | helper-fecha('DD/MM/YYYY') }} despues de las {{ datos.hora }}:00
                    </p>
                </div>
                <div class="col-7">
                    <div v-show="false" class="row mx-0 justify-center">
                        <div class="bg-light-f5 f-15 py-1 border text-general px-3 br-8 mx-2">
                            Total de la orden:
                            <b class="mx-1">
                                {{ convertMoneyTendero(datos.total,$tienda.idm_moneda) }}
                            </b>
                        </div>
                        <div class="ticket text-general py-1 px-3 br-8 mx-2">
                            Tus ganancias
                            <b class="count">
                                {{ convertMoneyTendero(datos.ganancia,$tienda.idm_moneda) }}
                            </b>
                        </div>
                    </div>
                    <div class="row mx-0 justify-center mt-5">
                        <div class="bg-general text-white px-4 f-14 py-1 br-8 mx-2 cr-pointer" @click="ir_pedido">
                            Ir al detalle del pedido
                        </div>
                        <div class="border text-general f-14 px-5 py-1 br-8 mx-2 cr-pointer" @click="ir_carrito">
                            Ir al catálogo
                        </div>
                    </div>
                    <div v-show="datos.boton" class="row mx-0 justify-center mt-3">
                        <div class="border text-general f-14 px-5 py-1 br-8 mx-2 cr-pointer" @click="$refs.modalPagoPayu.toggle(datos.total)">
                            Pagar en Linea
                        </div>
                        <!-- <div class="border text-general f-14 px-3  br-8 mx-2 cr-pointer" @click="crearBotonPayu">
                            <img src="/img/icons/boton_payu.png" alt="" width="250" height="80"/>
                        </div>
                        <div id="idPayuButtonContainer">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <modal-pago ref="modalPagoPayu" />
    </section>
</template>

<script>
import Pedidos from '~/services/pedidos/pedidos-tendero'
export default {
    components:{
        modalPago: () => import("../partials/modalPagoPayu"),
    },
    data(){
        return {
            url_image: '/img/ilustraciones/pedido_realizado.svg',
            datos:{
                id:null,
                vista:1,
                total:0,
                ganancia:0,
                fecha:'',
                hora:0,
                nombre_cliente:''
            }
        }
    },
    computed:{
        id_pedido(){
            return this.$route.query.id_pedido
        },
        id_admin_pedido(){
            return this.$route.query.id_admin_pedido
        }
    },
    mounted(){
        this.loadData()
    },
    methods:{
        async loadData(){
            try {

                const {data} = await Pedidos.get_datos_compra(this.$route.query)

                this.datos.id = data.id_pedido ? data.id_pedido : data.id_admin_pedido
                this.datos.vista = data.id_pedido ? 1 : 2 // 1 vista de pedido 2 vista de admin pedido
                this.datos.total = data.total
                this.datos.boton = data.boton
                this.datos.ganancia = data.ganancia
                this.datos.fecha = data.fecha_entrega
                this.datos.hora = data.horario
                this.datos.nombre_cliente = data.nombre_cliente

            } catch (e){
                this.error_catch(e)
            }
        },
        async crearBotonPayu(){

            let id = null;
            if(this.datos.boton){ // Solo aplica para Admin Pedidos
                id = this.id_admin_pedido
            }else{
                return
            }

            const {data} = await Pedidos.get_datos_payu(id)

            return;

            const infoPago = data.infoPago

            var html_button="<form id='formPago' method='post' action='https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu'>\
              <input name='merchantId'    type='hidden'  value='"+infoPago.merchantId+"'   >\
              <input name='accountId'     type='hidden'  value='"+infoPago.accountId+"'   >\
              <input name='extra1'     type='hidden'  value='"+this.id_admin_pedido+"'   >\
              <input name='extra2'     type='hidden'  value='"+infoPago.dataBase+"'   >\
              <input name='description'   type='hidden'  value='"+infoPago.description+"'   >\
              <input name='referenceCode' type='hidden'  value='"+infoPago.referenceCode+"'   >\
              <input name='amount'        type='hidden'  value='"+infoPago.amount+"'   >\
              <input name='tax'           type='hidden'  value='"+infoPago.tax+"'   >\
              <input name='taxReturnBase' type='hidden'  value='"+infoPago.taxReturnBase+"'   >\
              <input name='currency'      type='hidden'  value='"+infoPago.currency+"'   >\
              <input name='signature'     type='hidden'  value='"+infoPago.signature+"'   >\
              <input name='test'          type='hidden'  value='"+infoPago.test+"'   >\
              <input name='buyerEmail'    type='hidden'  value='"+infoPago.buyerEmail+"'   >\
              <input name='buyerFullName'    type='hidden'  value='"+infoPago.buyerFullName+"'   >\
              <input name='responseUrl'    type='hidden'  value='"+infoPago.responseUrl+"'   >\
              <input name='confirmationUrl'    type='hidden'  value='"+infoPago.confirmationUrl+"'   >\
            </form>";

            var elem = document.getElementById('idPayuButtonContainer');
            elem.innerHTML = html_button;
            document.getElementById('formPago').submit();

        },
        ir_pedido(){
            if(this.datos.vista === 1){
                this.$router.push({name:'tendero.pedidos',params:{id_pedido:this.datos.id}})
                return
            }
            if(this.datos.vista === 2){
                this.$router.push({name:'tendero.almacen.historial-pedidos'})
                return
            }
        },
        ir_carrito(){
            this.$router.push({name:'tendero.carrito'})
        }
    }
}
</script>

<style lang="scss" scoped>
.br-t-12{
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.ticket{
    border: 1px solid #28D07B;
    background-color: #28d07b26;
    .count{
        color: #28D07B;
        font-weight: 600;
    }
}
</style>
